import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout/layout';
import './AllPhones.scss'
import { graphql, Link, useStaticQuery } from 'gatsby';
//@ts-ignore
import bgImage from "../images/phnBanner.jpg";
import { FilterAndSort } from '../svg/icons';
import Seo from '../components/Seo/seo';

const AllPhones = () => {
    const data = useStaticQuery(graphql`  
    {
    allStrapiPhone {
        nodes {
        id
        title
        slug
        phone_manufacturer {
            Manufacturer
        }
        image {
            localFile {
            childImageSharp {
                fixed {
                src
                }
                gatsbyImageData(layout: CONSTRAINED, width: 200)
            }
            }
        }
        }
    }
    }
  `);

    const [show, setShow] = useState(false)
    const [val, setVal] = useState([])
    const [allPhones, setAllPhones] = useState(data.allStrapiPhone.nodes)


    useEffect(() => {
        if (document.getElementsByClassName('checkbox-menu')[0] !== null) {
            if (show === false) {
                (document.getElementsByClassName('checkbox-menu') as HTMLCollectionOf<HTMLElement>)[0].style.display = "none";
            }
            else {
                (document.getElementsByClassName('checkbox-menu') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'block';
            }
        }
    }, [show])

    useEffect(() => {
        var checkboxes = document.getElementsByClassName('checkedPhone')
        var selectedCboxes = Array.prototype.slice.call(checkboxes).filter(ch => ch.checked == true);
        var valArr = selectedCboxes.map((e) => e.value)
        setVal(valArr)
    }, [val])

    var manufacturersName = data.allStrapiPhone.nodes.map((e) => e.phone_manufacturer.Manufacturer)
    manufacturersName = Array.from(new Set(manufacturersName));

    useEffect(() => {
        if (val.length !== 0) {
            setAllPhones(data.allStrapiPhone.nodes
                .filter((el) => {
                    return val.some((f) => {
                        return f === el.phone_manufacturer.Manufacturer;
                    })
                }))
        }
        else {
            setAllPhones(data.allStrapiPhone.nodes)
        }
    })

    return (
        <Layout>
            <Seo title={' Buy Latest Smartphones|Phonera.in'}
                description={'Buy best smartphones online at affordable price. Visit us now to explore everything about mobile phones including mobile prices, reviews, feature.'}
            />
            <div className='container mt-3 px-2'>
                <header className='main-phn'>
                    <img width={1265} height={133} src={bgImage} alt="" />
                    <section className='hero-header-text'>
                        <h1>Phone Finder</h1>
                    </section>
                </header>
            </div>
            <div className='container mt-3 px-2'>
                <div className="filter-section mt-3">
                    <div className='d-flex justify-content-between'>
                        <h4 className='filters-heading' onClick={() => setShow(!show)}><span className=''><FilterAndSort /></span>Filters
                        </h4>
                        <span>{`${allPhones.length} Phones`}</span>
                    </div>
                    <ul className="checkbox-menu">
                        {manufacturersName.map((e, i) => {
                            return (
                                <li className='checkbox'>
                                    <label>
                                        <input className='checkedPhone' type="checkbox" name={e} value={e} /> {e}
                                    </label>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className='container mt-3 px-2'>
                <div className='row mb-3'>
                    {allPhones.map((e) => {
                        return (
                            <div className='col-3 phn mb-3' key={e.id}>
                                <div className='phn-inner'>
                                    <Link to={`${e.slug}`}>
                                        <GatsbyImage
                                            image={
                                                e.image[0]?.localFile?.childImageSharp?.gatsbyImageData
                                            }
                                            loading="eager"
                                            alt="specs-banner"
                                            className='phn-img'
                                        />
                                        <section>{e.title}</section>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Layout >
    )
}

export default AllPhones